.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary {
  margin: 0 20px;
  background-color: rgb(255, 255, 255);
}

.nota-header {
  margin-bottom: 2px;
  box-shadow: 5px 5px 10px #aaaaaa;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  top: 0px;
  z-index: 100;
  padding: 0 5px;
}

.nota-header-img {
  max-height: 40px
}

.nota-header-menu {
  display: none
}

.nota-btn-header {
  font-size: 16px;
  padding: 5px;
  font-weight: bold;
  color: #757575;
  border: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0);
  margin: 0 20px
}

.nota-footer-title {
  padding-top: 5px;
  padding-bottom:0px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
}

.nota-footer-img {
  display: none
}

.nota-introduce {
  width: 100%;
  background-color: white;
  background-image: url('./../public/images/Frame105.jpg');
}

.nota-introduce-ele {
  margin: 20px;
  padding: 2px;
  /* height: 250px; */
  /* width: 200px; */
  background-color: #fff;
  box-shadow: 1px 1px 5px #aaaaaa
}

.nota-introduce-ele:hover {

}
.nota-introduce-title {
  font-weight: bold;
}

.nota-product-ele {
  padding: 30px;
}

.nota-product-img {
  max-height: 216px;
  max-width: 90%;
  width: auto;
}

.nota-product-head {
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px
}

.nota-partner-ele {
  padding: 20px;
  max-height: 190px;
}

.nota-partner-img {
  max-width: 100%;
}

.nota-carierr {
  background-color: white;
  background-image: url('./../public/images/bg2.jpg');
  color: #ffffff;
}

.nota-carierr-ele {
  padding: 20px;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  width: 100%;
}

.nota-carierr-title {
  font-weight: bold;
}

#introduce {
  padding-top: 70px;
}
#product {
  padding-top: 70px;
}
#partner {
  padding-top: 70px;
}
#carierr {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) {
  .primary {
    padding: 0 10%;
  }

  .nota-header {
    padding: 0 10%;
    margin: 0 5px
  }

  .nota-introduce-ele {
    padding: 20px
  }

  .nota-header-menu {
    display: inline
  }

  .nota-header-img {
    max-height: 50px
  }


.nota-footer-img {
  display: inline
}

}
